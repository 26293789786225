const headersList = [
  { title: "App name", width: "10%", DB_field: "appName" },
  { title: "User", width: "10%", DB_field: "user" },
  { title: "Timestamp", width: "20%", DB_field: "date" },
  { title: "Context", width: "10%", DB_field: "context" },
  { title: "Message", width: "45%", DB_field: "message" },
  { title: "Extra", width: "5%", DB_field: "extra" },
];

export default headersList;
