import "./index.scss";

import React from "react";

import moment from "moment";

import JsonViewer from "searchable-react-json-view";

import copy from "copy-to-clipboard";

import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as CloseIconError } from "../../assets/icons/close-icon-error.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/paste-svgrepo-com.svg";

import { LogsType } from "../logs-view/types/logsType";

import { toast } from "react-toastify";

type SlideOutPanelPropsTypes = {
  data: LogsType | undefined;
  onClose: any;
  isDebug: boolean;
};

const SlideOutPanel = ({
  data,
  onClose,
  isDebug = true,
}: SlideOutPanelPropsTypes) => {
  const [searchValue, setSearchValue] = React.useState("");
  return (
    <div
      className={`slide-out-panel `}
      style={
        isDebug
          ? {
              background:
                "linear-gradient(267.26deg, #f1fffc -14.03%, #fffcf7 123.32%)",
              boxShadow: "0px 0px 12px rgba(30, 161, 130, 0.3)",
            }
          : {
              background:
                "linear-gradient(267.26deg, #ffeecb -14.03%, #fffcf7 123.32%)",
              boxShadow: "0px 0px 12px rgba(206, 197, 149, 0.3)",
            }
      }
    >
      <div className="header-slide">
        <div className="details">Details</div>
        <div onClick={() => onClose()} className="close-icon">
          {isDebug ? (
            <CloseIcon width={15} height={15} />
          ) : (
            <CloseIconError width={15} height={15} />
          )}
        </div>
      </div>
      <input
        className="input"
        type="text"
        placeholder="search"
        onChange={(e) => {
          setSearchValue(e?.target?.value);
        }}
      />
      <JsonViewer
        src={
          {
            ...data,
            date: moment(data?.date).format("DD/MM/YYYY HH:mm:ss:SSS"),
          } || {}
        }
        highlightSearch={searchValue}
        highlightSearchColor={isDebug ? "rgba(206, 236, 220, 1)" : "#ECCA85"}
        highlightCurrentSearchColor="#159B7B"
        displayDataTypes={false}
        customCopiedIcon={<span></span>}
        customCopyIcon={<span></span>}
        iconStyle={"square"}
        customActions={[
          {
            icon: <CopyIcon width={12} height={12} fill="#DD6900" />,
            onClick: (value: any) => {
              copy(JSON.stringify(value));
              toast.info("Text Copied !");
            },
          },
        ]}
        displayObjectSize={false}
        style={{
          background: "transparent",
          display: "flex",
          marginLeft: "3%",
          whiteSpace: "normal",
          overflow: "scroll",
        }}
        theme={isDebug ? "shapeshifter:inverted" : "bright:inverted"}
      />
    </div>
  );
};
export default SlideOutPanel;
