import React from "react";

import "./index.scss";

type SearchUiInputPropsTypes = {
  handleOnchangeEvent: Function;
  handleOnSubmitForm: Function;
};

function SearchUiInput({
  handleOnchangeEvent,
  handleOnSubmitForm,
}: SearchUiInputPropsTypes): JSX.Element {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleOnSubmitForm(e);
      }}
      className="form-search"
    >
      <input
        type="search"
        placeholder=""
        onChange={(e) => {
          handleOnchangeEvent(e);
        }}
      />
      <button
        type="submit"
        onClick={(e) => {
          handleOnSubmitForm(e);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        Search
      </button>
    </form>
  );
}

export default SearchUiInput;
