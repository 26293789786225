import { toast } from "react-toastify";
import filterDataFromDB from "../../../../../services/filterDataFromDB";
import {
  DEBUG_LOGS_SET_LOADING,
  DEBUG_LOGS_UNSET_LOADING,
} from "../../../../../store/slices/reducers/debugLogs";
import {
  handleChangeInputFilterFieldArgsType,
  handleFilterDataFromDBArgsType,
} from "./filterDbInputsTypes";
import {
  ERRORS_LOGS_SET_LOADING,
  ERRORS_LOGS_UNSET_LOADING,
} from "../../../../../store/slices/reducers/errorsLogs";

function handleChangeInputFilterField(
  args: handleChangeInputFilterFieldArgsType
) {
  args?.setInputValues((prev: any) => {
    return {
      ...prev,
      [args?.headerDetail?.DB_field]: args?.e?.target?.value,
    };
  });
}

//***************************  HANDLE FILTER FROM DB ******************************\\
function handleFilterDataFromDB(args: handleFilterDataFromDBArgsType) {
  if (!Object.keys(args?.inputValues || {})?.length) return;
  args?.store.dispatch({
    type: DEBUG_LOGS_SET_LOADING,
  });
  filterDataFromDB("debug", args?.filterPage, args?.inputValues)
    .then((res) => {
      args?.store.dispatch({
        type: DEBUG_LOGS_UNSET_LOADING,
      });
      args?.setIsFiltredFromDB(true);
      args?.setFiltredDataFromDB(res?.data?.filteredLogs || []);
      args?.setTotalPages(res?.data?.totalPage);
    })
    .catch((err) => {
      args?.store.dispatch({
        type: DEBUG_LOGS_UNSET_LOADING,
      });
      toast.error("Filter LOGS from DB error :" + err?.message);
      console.error("filterDataFromDB Error : ", err?.message);
    });
}

function handleFilterDataFromDbForErrors(args: handleFilterDataFromDBArgsType) {
  if (!Object.keys(args?.inputValues || {})?.length) return;
  args?.store.dispatch({
    type: ERRORS_LOGS_SET_LOADING,
  });
  filterDataFromDB("errors", args?.filterPage, args?.inputValues)
    .then((res) => {
      args?.store.dispatch({
        type: ERRORS_LOGS_UNSET_LOADING,
      });
      args?.setIsFiltredFromDB(true);
      args?.setFiltredDataFromDB(res?.data?.filteredLogs || []);
    })
    .catch((err) => {
      args?.store.dispatch({
        type: ERRORS_LOGS_UNSET_LOADING,
      });
      toast.error("Filter LOGS from DB error :" + err?.message);
      console.error("filterDataFromDB Error : ", err?.message);
    });
}

export {
  handleChangeInputFilterField,
  handleFilterDataFromDbForErrors,
  handleFilterDataFromDB,
};
