import "./index.scss";

import React from "react";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function CustomToast() {
  return (
    <div>
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={6}
        theme="light"
        closeButton={true}
      />
    </div>
  );
}

export default CustomToast;
