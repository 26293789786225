import "./index.scss";

import React from "react";

import { useSocket, useSocketConfig } from "../../socket/SocketConnection";

import { useStore } from "react-redux";

import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg";

import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";

import { ReactComponent as Logout } from "../../assets/icons/logout.svg";

import {
  DEBUG_LOGS_ADD_ALL_LOGS,
  DEBUG_LOGS_ADD_SINGLE_LOG,
} from "../../store/slices/reducers/debugLogs";

import {
  ERRORS_LOGS_ADD_ALL_LOGS,
  ERRORS_LOGS_ADD_SINGLE_LOG,
} from "../../store/slices/reducers/errorsLogs";

import getLast1000Documents from "../../services/getLast1000Documents";

import ErrorsLogsView from "./errors-view";
import DebugLogsView from "./debug-view";

import imxLoggerLogo from "../../assets/logo/imxLoggezr_logo.png";
import LogoutModal from "../logout-modal";

function LoggerView() {
  const socket = useSocket({
    socket: useSocketConfig(),
  });

  const store = useStore();

  const [isDebug, setIsDebug] = React.useState<boolean>(true);

  const [openLogoutModal, setOpenLogoutModal] = React.useState<boolean>(false);

  const [refreshOnReconnectSocket, setRefreshOnReconnectSocket] =
    React.useState<boolean>(socket.connected || true);

  function logout() {
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  function handleCloseModal() {
    setOpenLogoutModal(false);
  }

  function handleOpenLogoutModal() {
    setOpenLogoutModal(true);
  }

  /************** GET LAST 1000 ERROR LOGS **************/
  React.useEffect(() => {
    if (!refreshOnReconnectSocket) return;
    getLast1000Documents("errors").then((res) => {
      store.dispatch({
        type: ERRORS_LOGS_ADD_ALL_LOGS,
        payload: res?.data?.documents,
      });
    });
    return () => {};
    //eslint-disable-next-line
  }, [refreshOnReconnectSocket]);

  /************** SOCKET LISTEN TO NEW ERRORS LOGS **************/
  React.useEffect(() => {
    socket.on("errors", (data: any) => {
      store.dispatch({
        type: ERRORS_LOGS_ADD_SINGLE_LOG,
        payload: JSON.parse(data)?.logLineObject,
      });
    });
    return () => {};
    //eslint-disable-next-line
  }, []);

  /************** GET LAST 1000 DEBUG LOGS **************/
  React.useEffect(() => {
    if (!refreshOnReconnectSocket) return;
    getLast1000Documents("debug").then((res) => {
      store.dispatch({
        type: DEBUG_LOGS_ADD_ALL_LOGS,
        payload: res?.data?.documents,
      });
    });
    return () => {};
    //eslint-disable-next-line
  }, [refreshOnReconnectSocket]);

  /************** SOCKET LISTEN TO NEW DEBUG LOGS **************/
  React.useEffect(() => {
    socket.on("debug", (data: any) => {
      store.dispatch({
        type: DEBUG_LOGS_ADD_SINGLE_LOG,
        payload: JSON.parse(data)?.logLineObject,
      });
    });
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <div className="page" id="scroller">
      <div
        className="header-line"
        style={
          !isDebug
            ? {
                background: "#DF9F1C",
              }
            : {}
        }
      >
        <div className="header-line" />
      </div>
      <div className="level-choice">
        <div
          style={{
            display: "flex",
          }}
        >
          <img
            src={imxLoggerLogo}
            alt="IMX LOGGER LOGO"
            className="logo-style"
          />

          <button
            className={isDebug ? "level-button" : "level-unselected"}
            onClick={() => {
              setIsDebug(true);
            }}
          >
            Debug
          </button>
          <button
            className={!isDebug ? "level-button" : "level-unselected"}
            onClick={() => {
              setIsDebug(false);
            }}
            style={
              !isDebug
                ? {
                    color: "#DF9F1C",
                  }
                : {}
            }
          >
            Error
          </button>
        </div>

        <div className="second-container">
          <button
            className="extra-feature-button"
            onClick={() => {
              if (refreshOnReconnectSocket) {
                socket.disconnect();
                setRefreshOnReconnectSocket(false);
              } else {
                socket.connect();
                setRefreshOnReconnectSocket(true);
              }
            }}
          >
            {refreshOnReconnectSocket ? (
              <PauseIcon
                height={20}
                width={20}
                style={{
                  marginRight: 10,
                }}
              />
            ) : (
              <PlayIcon
                height={20}
                width={20}
                style={{
                  marginRight: 10,
                }}
              />
            )}
            {refreshOnReconnectSocket ? "Pause" : "Start"}
          </button>
        </div>
        <button onClick={handleOpenLogoutModal} className="logout-button">
          <p>Logout</p>
          <Logout width={12} height={12} />
        </button>
      </div>
      <div>{isDebug ? <DebugLogsView /> : <ErrorsLogsView />}</div>
      {openLogoutModal ? (
        <LogoutModal
          onClose={handleCloseModal}
          onValidateLogout={logout}
        ></LogoutModal>
      ) : null}
    </div>
  );
}

export default LoggerView;
