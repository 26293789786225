import axios from "axios";

import { LogsType } from "../components/logs-view/types/logsType";
import { toast } from "react-toastify";

type DataFromAxiosType = {
  data: {
    filteredLogs: LogsType[];
    count: number;
    totalPage: number;
  };
};

type queryType = {
  appName?: string;
  context?: string;
  date?: string;
  message?: string;
  user?: string;
};

async function filterDataFromDB(
  level: "errors" | "debug",
  page: number,
  query: queryType = {}
): Promise<DataFromAxiosType | void> {
  try {
    const { data }: DataFromAxiosType = await axios.post(
      `${
        process.env?.REACT_APP_SERVER_URL || "http://localhost:5000"
      }/logs/filterLogsQuery/${level}/${page}`,
      query
    );

    toast("Data filtred from DB successfully ");

    return {
      data,
    };
  } catch (error: any) {
    toast("Filter data from DB error: " + error?.message);
    console.error("Error in filterDataFromDB ", error);
  }
}

export default filterDataFromDB;
