import React from "react";

import "./index.scss";

import searchInputList from "../../helpers/searchInputList";

type FilterDbInputsPropsType = {
  handleFilterDataFromDB: Function;
  inputValues: any;
  handleChangeInputFilterField: Function;
  isDebug: boolean;
  resetFilter: Function;
  isFiltredFromDB: boolean;
};

function FilterDbInputs({
  handleFilterDataFromDB,
  inputValues,
  handleChangeInputFilterField,
  isDebug,
  resetFilter,
  isFiltredFromDB,
}: FilterDbInputsPropsType) {
  return (
    <tr className="filter-db-container">
      {searchInputList?.map((headerDetail, index) => {
        return (
          <td
            className="all-filter-container"
            key={index}
            // colSpan={headerDetail?.DB_field === "message" ? 2 : 1}
          >
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleFilterDataFromDB();
              }}
              className="form-filter-container"
              style={
                isDebug
                  ? {}
                  : {
                      border: "1px solid #DF9F1C",
                    }
              }
            >
              <input
                type="text"
                className="input-filter-header"
                placeholder={"Enter " + headerDetail?.title}
                name={headerDetail?.title}
                id={headerDetail?.title}
                value={inputValues[headerDetail.DB_field] || ""}
                onChange={(e) => handleChangeInputFilterField(e, headerDetail)}
              />
              <input type="submit" hidden />
            </form>
          </td>
        );
      })}
      <td>
        <button
          onClick={() => resetFilter()}
          disabled={!isFiltredFromDB}
          style={
            !isFiltredFromDB ? { cursor: "no-drop" } : { cursor: "pointer" }
          }
          className="reset-button"
        >
          Reset
        </button>
      </td>
    </tr>
  );
}

export default FilterDbInputs;
