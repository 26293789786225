import { handleFilterNextPageArgsTypes } from "./filterDbPaginationTypes";

function handleFilterNextPage(args: handleFilterNextPageArgsTypes) {
  args?.setFilterPage((prePage: number) => prePage + 1);
}

function handleFilterPreviousPage(args: handleFilterNextPageArgsTypes) {
  args?.setFilterPage((prePage: number) => prePage - 1);
}

export { handleFilterNextPage, handleFilterPreviousPage };
