const searchInputList: searchInputListType[] = [
  { title: "App name", width: "20%", DB_field: "appName" },
  { title: "User", width: "20%", DB_field: "user" },
  { title: "Timestamp", width: "20%", DB_field: "date" },
  { title: "Context", width: "20%", DB_field: "context" },
  { title: "Message", width: "20%", DB_field: "message" },
];

export type searchInputListType = {
  title: string;
  width: string;
  DB_field: string;
};

export default searchInputList;
