import "./index.scss";

import React from "react";

import { useStore } from "react-redux";

import { useSelector } from "react-redux";

import { useSelectorStateType } from "../../../store/types/useSelectorStateType";

import { ReactComponent as TopArrow } from "../../../assets/icons/topArrowSecondary.svg";
import { ReactComponent as ClearDbIcon } from "../../../assets/icons/clearDbIcon.svg";

import SlideOutPanel from "../../slideout-panel";
import RowData from "../row-data";

import headersList from "../helpers/headersList";

import { searchInputListType } from "../helpers/searchInputList";

import { LogsType } from "../types/logsType";

import Lottie from "react-lottie";

import loaderFile from "../../../assets/loading/errors-loading.json";

import noDataFile from "../../../assets/loading/no-data-found.json";

import { toast } from "react-toastify";

import SearchUiInput from "../debug-view/search-ui-input";

import {
  handleChangeSearchUIinputField,
  searchFromUI,
} from "../debug-view/helpers/search-ui-events/searchUiEvents";

import FilterDbPagination from "../debug-view/filter-db-pagination";

import {
  handleFilterNextPage,
  handleFilterPreviousPage,
} from "../debug-view/helpers/filter-db-pagination-events/filterDbPaginationEvent";

import FilterDbInputs from "../debug-view/filter-db-inputs";

import {
  handleChangeInputFilterField,
  handleFilterDataFromDbForErrors,
} from "../debug-view/helpers/filter-db-inputs-events/FilterDbInputsEvents";

import ClearDbModal from "../debug-view/clear-db-modal";

import {
  clearDbSubmit,
  handleChangeCheckbox,
} from "../debug-view/helpers/clear-db-modal-events/clearDbModalEvents";

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderFile,
};

const noDataOptions = {
  loop: true,
  autoplay: true,
  animationData: noDataFile,
};

const LOGS_LIMIT = 1000;

function ErrorsLogsView(): JSX.Element {
  const store = useStore();

  const allErrorsLogs: LogsType[] = useSelector(
    (state: useSelectorStateType) => state?.entities?.errorsLogs?.errorsLogs
  );

  const loadingErrorsData: boolean = useSelector(
    (state: useSelectorStateType) =>
      state?.entities?.errorsLogs?.loadingErrorsLogs
  );

  const [open, setOpen] = React.useState<boolean>(false);

  const [showPanel, setShowPanel] = React.useState<boolean>(false);

  const [filterPage, setFilterPage] = React.useState<number>(1);

  const [panelData, setPanelData] = React.useState<LogsType>();

  const [filtredDataFromDB, setFiltredDataFromDB] = React.useState<LogsType[]>(
    []
  );

  const [totalPages, setTotalPages] = React.useState<number>(1);

  const [loadingClearDb, setLoadingClearDb] = React.useState<boolean>(false);

  const [isFiltredFromDB, setIsFiltredFromDB] = React.useState<boolean>(false);

  const [serchedData, setSerchedData] = React.useState<LogsType[]>();

  const [inputValues, setInputValues] = React.useState<any>({});

  const [clearDbCheckedField, setClearDbCheckedField] = React.useState<any>({});

  const [isSearchedFromUI, setIsSearchedFromUI] =
    React.useState<boolean>(false);

  const [showTopBtn, setShowTopBtn] = React.useState<boolean>(false);

  const [searchedString, setSearchedString] = React.useState<string>("");

  //******************** HANDLE OPEN/CLOSE CLEAR DB MODAL*****************\\
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  //********************************************** RESET FILTER **********************************/
  function resetFilter() {
    setIsFiltredFromDB(false);
    setFiltredDataFromDB([]);
    setTotalPages(1);
    setInputValues({});
    setFilterPage(1);
    toast("Filter reset successfully !");
  }

  //********************************************** ON ROW CLICK **********************************/
  function handleRowClick(data: LogsType) {
    setShowPanel(true);
    setPanelData(data);
  }

  //********************************* USE EFFECT For  Filter pagination update ********************\\
  React.useEffect(() => {
    if (!isFiltredFromDB) return;
    handleFilterDataFromDbForErrors({
      filterPage,
      inputValues,
      setFiltredDataFromDB,
      setIsFiltredFromDB,
      store,
      setTotalPages,
    });
    return () => {};
    //eslint-disable-next-line
  }, [filterPage]);

  //********************************* USE EFFECT FOR EVENT LISTENNER ON SCROLL **********************\\
  React.useEffect(() => {
    window.addEventListener(
      "scroll",
      (e) => {
        setShowTopBtn(true);
      },
      true
    );
    return () => {
      window.removeEventListener(
        "scroll",
        (e) => {
          setShowTopBtn(false);
        },
        true
      );
    };
    //eslint-disable-next-line
  }, []);

  //********************************* USE EFFECT FOR Search while REAL TIME LOGS *****************\\
  React.useEffect(() => {
    isSearchedFromUI &&
      searchFromUI({
        e: { target: [{ value: searchedString }] },
        allDebugLogs: allErrorsLogs,
        filtredDataFromDB,
        isFiltredFromDB,
        setIsSearchedFromUI,
        setSearchedString,
        setSerchedData,
      });
    return () => {};
    //eslint-disable-next-line
  }, [allErrorsLogs?.length, allErrorsLogs?.[0]?._id]);

  return (
    <div className="errors-view-container">
      <div className="search-row-style">
        <div className="search-container">
          <SearchUiInput
            handleOnSubmitForm={(e: React.FormEvent<HTMLFormElement>) =>
              searchFromUI({
                e,
                allDebugLogs: allErrorsLogs,
                filtredDataFromDB,
                isFiltredFromDB,
                setIsSearchedFromUI,
                setSearchedString,
                setSerchedData,
              })
            }
            handleOnchangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeSearchUIinputField({
                e,
                setIsSearchedFromUI,
              });
            }}
          />

          {isFiltredFromDB ? (
            <FilterDbPagination
              totalPages={totalPages}
              LOGS_LIMIT={LOGS_LIMIT}
              filterPage={filterPage}
              filtredDataFromDB={filtredDataFromDB}
              handleFilterNextPage={() =>
                handleFilterNextPage({ setFilterPage })
              }
              handleFilterPreviousPage={() =>
                handleFilterPreviousPage({ setFilterPage })
              }
              loadingDebugµData={loadingErrorsData}
            />
          ) : null}
        </div>
        <button className="clear-db-button" onClick={handleOpen}>
          <ClearDbIcon width={18} height={18} />
          <div className="clear-db-text"> Clear db</div>
        </button>
        <button
          style={
            !isFiltredFromDB ? { cursor: "no-drop" } : { cursor: "pointer" }
          }
          className="reset--filter-button"
          onClick={resetFilter}
          disabled={!isFiltredFromDB}
        >
          Reset Filter
        </button>
        <div className="count-limit">
          <p>Total logs</p>
          <p className="value">
            {
              (isSearchedFromUI
                ? serchedData
                : isFiltredFromDB
                ? filtredDataFromDB
                : allErrorsLogs
              )?.length
            }
          </p>
          <p> Limit </p>
          <p className="value">{LOGS_LIMIT}</p>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            {headersList?.map((headerDetail, index) => {
              return (
                <th style={{ width: headerDetail?.width || "10%" }} key={index}>
                  {headerDetail?.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <FilterDbInputs
            isDebug={false}
            handleChangeInputFilterField={(
              e: React.ChangeEvent<HTMLInputElement>,
              headerDetail: searchInputListType
            ) =>
              handleChangeInputFilterField({ e, headerDetail, setInputValues })
            }
            inputValues={inputValues}
            handleFilterDataFromDB={() =>
              handleFilterDataFromDbForErrors({
                filterPage,
                inputValues,
                setFiltredDataFromDB,
                setIsFiltredFromDB,
                store,
                setTotalPages,
              })
            }
            resetFilter={() => resetFilter()}
            isFiltredFromDB={isFiltredFromDB}
          />

          {loadingErrorsData ? (
            <tr>
              <td className="loader-container" rowSpan={20} colSpan={9}>
                <Lottie options={loadingOptions} height={150} width={200} />
              </td>
            </tr>
          ) : (isSearchedFromUI && serchedData?.length === 0) ||
            (isFiltredFromDB && filtredDataFromDB.length === 0) ||
            (!isFiltredFromDB && allErrorsLogs.length === 0) ? (
            <tr>
              <td className="no-item-container" rowSpan={20} colSpan={9}>
                <Lottie options={noDataOptions} height={150} width={200} />
                <p className="no-item-text"> No Data Found !</p>
              </td>
            </tr>
          ) : (
            (isSearchedFromUI
              ? serchedData
              : isFiltredFromDB
              ? filtredDataFromDB
              : allErrorsLogs
            )?.map((logs: LogsType, index: number) => {
              return (
                <React.Fragment key={index}>
                  <RowData
                    logs={logs}
                    handleRowClick={handleRowClick}
                    panelData={panelData}
                  />
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
      {showPanel ? (
        <SlideOutPanel
          data={panelData}
          onClose={() => {
            setPanelData(undefined);
            setShowPanel(false);
          }}
          isDebug={false}
        />
      ) : null}
      {showTopBtn && (
        <button
          onClick={() => {
            document
              .getElementById("scroller")
              ?.scroll({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="scroll-button"
        >
          <TopArrow height={15} width={15} />
        </button>
      )}

      {open && (
        <ClearDbModal
          clearDbSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            clearDbSubmit({
              clearDbCheckedField,
              e,
              setClearDbCheckedField,
              setLoadingClearDb,
              setOpen,
              store,
            })
          }
          handleChangeCheckbox={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeCheckbox({ e, setClearDbCheckedField })
          }
          handleClose={() => handleClose()}
          loadingClearDb={loadingClearDb}
          setClearDbCheckedField={setClearDbCheckedField}
        />
      )}
    </div>
  );
}

export default ErrorsLogsView;
