import axios from "axios";

type DataFromAxiosType = {
  data: {
    message: string;
    content: any;
    success: boolean;
  };
};

async function LoginService(body: {
  username: string;
  password: string;
}): Promise<DataFromAxiosType | void | any> {
  try {
    const { data }: DataFromAxiosType = await axios.post(
      `${
        process.env?.REACT_APP_SERVER_URL || "http://localhost:5000"
      }/auth/login`,
      body
    );

    return {
      data,
    };
  } catch (error: any) {
    console.error("Error in LoginService ", error);
    return { error };
  }
}

export default LoginService;
