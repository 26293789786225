import { LogsType } from "../../../components/logs-view/types/logsType";

type dataSetType = {
  debugLogs: LogsType[];
  loadingDebugLogs: boolean;
};

const debugLogs = {
  initialState: { debugLogs: [] },
  reducers: {
    addAllDebugLogs(
      dataSet: dataSetType,
      action: { payload: LogsType[] | undefined }
    ) {
      dataSet.debugLogs = action?.payload ?? [];
    },

    addSingleDebugLog(dataSet: dataSetType, action: { payload: LogsType }) {
      // dataSet.debugLogs = [action?.payload, ...dataSet.debugLogs];
      const newDebugLog = action?.payload;
      const updatedDebugLogs = [
        newDebugLog,
        ...dataSet.debugLogs.slice(0, 999),
      ];
      dataSet.debugLogs = updatedDebugLogs;
    },

    setDebugLoading(dataSet: dataSetType, action: any) {
      dataSet.loadingDebugLogs = true;
    },

    unsetDebugLoading(dataSet: dataSetType, action: any) {
      dataSet.loadingDebugLogs = false;
    },

    reset(dataSet: dataSetType, action: any) {
      dataSet.debugLogs = [];
    },
  },
};

export default debugLogs;

export const DEBUG_LOGS_ADD_ALL_LOGS = "debugLogs/addAllDebugLogs";

export const DEBUG_LOGS_ADD_SINGLE_LOG = "debugLogs/addSingleDebugLog";

export const DEBUG_LOGS_SET_LOADING = "debugLogs/setDebugLoading";

export const DEBUG_LOGS_UNSET_LOADING = "debugLogs/unsetDebugLoading";

export const DEBUG_LOGS_RESET_LOGS = "debugLogs/reset";
