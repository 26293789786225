import axios from "axios";
import { toast } from "react-toastify";

type DataFromAxiosType = {
  data: {
    documents: any[];
    length: number;
  };
};

async function getLast1000Documents(
  level: "errors" | "debug"
): Promise<DataFromAxiosType | void> {
  try {
    const { data }: DataFromAxiosType = await axios.get(
      `${
        process.env?.REACT_APP_SERVER_URL || "http://localhost:5000"
      }/logs/getLast1000Documents/${level}`
    );
    toast(level?.toUpperCase() + " Logs got successfully");

    return {
      data,
    };
  } catch (error: any) {
    toast(level?.toUpperCase() + "Logs getting error: " + error?.message);
    console.error("Error in getLast1000Documents ", error);
  }
}

export default getLast1000Documents;
