import React from "react";

import "./index.scss";
import Lottie from "react-lottie";

import clearDatabase from "../../../../assets/loading/clear-db-loading.json";

type ClearDbModalPropsType = {
  handleClose: Function;
  setClearDbCheckedField: Function;
  loadingClearDb: boolean;
  clearDbSubmit: Function;
  handleChangeCheckbox: Function;
};

const clearDbLoadingLottie = {
  loop: true,
  autoplay: true,
  animationData: clearDatabase,
};

function ClearDbModal({
  handleClose,
  setClearDbCheckedField,
  loadingClearDb,
  clearDbSubmit,
  handleChangeCheckbox,
}: ClearDbModalPropsType) {
  return (
    <div
      className="modal"
      onClick={() => {
        handleClose();
        setClearDbCheckedField({});
      }}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {loadingClearDb ? (
          <>
            <Lottie options={clearDbLoadingLottie} height={150} width={200} />
            <p className="modal-title"> Loading ... </p>
          </>
        ) : (
          <div>
            <p className="modal-title">Select db to be cleared</p>
            <form onSubmit={(e) => clearDbSubmit(e)} className="form-container">
              <div className="check-box-container">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-1"
                  type="checkbox"
                  value="debug"
                  onChange={(e) => handleChangeCheckbox(e)}
                />
                <label htmlFor="styled-checkbox-1">Debug</label>
              </div>
              <div className="check-box-container">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-2"
                  type="checkbox"
                  value="errors"
                  onChange={(e) => handleChangeCheckbox(e)}
                />
                <label htmlFor="styled-checkbox-2">Errors</label>
              </div>
              <button type="submit" className="modal-submit-button">
                Clear
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClearDbModal;
