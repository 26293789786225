import "./index.scss";

import React from "react";

import moment from "moment";

import { ReactComponent as ExtraUndefined } from "../../../assets/icons/extraUndefined.svg";
import { ReactComponent as ExtraView } from "../../../assets/icons/extraView.svg";
import { ReactComponent as ExtraViewError } from "../../../assets/icons/extraViewError.svg";

import { LogsType } from "../types/logsType";

function RowData({
  logs,
  handleRowClick,
  panelData,
}: {
  logs: LogsType;
  panelData: LogsType | undefined;
  handleRowClick: Function;
}) {
  return (
    <React.Fragment>
      <tr onClick={() => handleRowClick(logs)} style={{ cursor: "pointer" }}>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
        >
          {logs?.appName || "-"}
        </td>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
        >
          {logs?.user || "-"}
        </td>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
        >
          {moment(logs?.date).format("DD/MM/YYYY HH:mm:ss:SSS") || "-"}
        </td>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
        >
          {logs?.context || "-"}
        </td>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
          style={{
            textAlign: "left",
          }}
        >
          {logs?.message || "-"}
        </td>
        <td
          className={
            logs?._id === panelData?._id ? `selected-td-${logs?.level}` : ""
          }
        >
          {logs?.extra && Object.keys(logs?.extra).length > 0 ? (
            logs?.level === "errors" ? (
              <ExtraViewError />
            ) : (
              <ExtraView />
            )
          ) : (
            <ExtraUndefined />
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default RowData;
