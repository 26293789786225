import React from "react";

import "./index.scss";

import { ReactComponent as LeftArrow } from "../../../../assets/icons/leftArrow.svg";
import { ReactComponent as DisabledLeftArrow } from "../../../../assets/icons/grayleftArrow.svg";

import { LogsType } from "../../types/logsType";

type FilterDbPaginationPropsType = {
  filterPage: Number;
  handleFilterPreviousPage: Function;
  loadingDebugµData: boolean;
  filtredDataFromDB: LogsType[];
  LOGS_LIMIT: number;
  handleFilterNextPage: Function;
  totalPages: number;
};

function FilterDbPagination({
  filterPage,
  handleFilterPreviousPage,
  loadingDebugµData,
  filtredDataFromDB,
  LOGS_LIMIT,
  handleFilterNextPage,
  totalPages,
}: FilterDbPaginationPropsType): JSX.Element {
  return (
    <div className="new-pagintion-container">
      <div className="pagintion-container">
        <button
          disabled={filterPage === 1 || loadingDebugµData}
          onClick={() => handleFilterPreviousPage()}
          className="next-prev-page-button"
        >
          {filterPage === 1 || loadingDebugµData ? (
            <DisabledLeftArrow width={12} height={12} />
          ) : (
            <LeftArrow width={12} height={12} />
          )}
        </button>
        <div className="page-number"> {"Page: " + filterPage}</div>
        <button
          disabled={
            filtredDataFromDB?.length === 0 ||
            filtredDataFromDB?.length < LOGS_LIMIT ||
            loadingDebugµData
          }
          className="next-prev-page-button"
          onClick={() => handleFilterNextPage()}
        >
          {filtredDataFromDB?.length === 0 ||
          filtredDataFromDB?.length < LOGS_LIMIT ||
          loadingDebugµData ? (
            <DisabledLeftArrow
              width={12}
              height={12}
              style={{
                transform: "rotate(180deg)",
              }}
            />
          ) : (
            <LeftArrow
              width={12}
              height={12}
              style={{
                transform: "rotate(180deg)",
              }}
            />
          )}
        </button>
      </div>
      <p className="max-pages">Total Pages : {totalPages}</p>
    </div>
  );
}

export default FilterDbPagination;
