import { toast } from "react-toastify";

import {
  clearDbSubmitArgsType,
  handleChangeCheckboxArgsType,
} from "./clearDbModalEventsTypes";

import clearDb from "../../../../../services/clearDb";

import { DEBUG_LOGS_RESET_LOGS } from "../../../../../store/slices/reducers/debugLogs";

import { ERRORS_LOGS_RESET_LOGS } from "../../../../../store/slices/reducers/errorsLogs";

function clearDbSubmit(args: clearDbSubmitArgsType) {
  args?.e?.preventDefault();
  args?.setLoadingClearDb(true);
  if (!args?.clearDbCheckedField?.debug && !args?.clearDbCheckedField?.errors) {
    args?.setOpen(false);
    args?.setLoadingClearDb(false);
    toast.error("You must select at least one choice !");
    return;
  }
  clearDb(args?.clearDbCheckedField)
    .then((res) => {
      if (args?.clearDbCheckedField?.debug) {
        args?.store.dispatch({
          type: DEBUG_LOGS_RESET_LOGS,
        });
      }
      if (args?.clearDbCheckedField?.errors) {
        args?.store.dispatch({
          type: ERRORS_LOGS_RESET_LOGS,
        });
      }
      args?.setOpen(false);
      args?.setLoadingClearDb(false);
      args?.setClearDbCheckedField({});
    })
    .catch((err) => {
      args?.setLoadingClearDb(false);
      toast.error("Error while cleaning DB: " + err?.message);
      console.error("clearDbSubmit Error : ", err?.message);
    });
}

//*************************** ON CHNAGE EVENT CLEAR DB CHECKBOX ******************************\\
function handleChangeCheckbox(args: handleChangeCheckboxArgsType) {
  args?.setClearDbCheckedField((prev: any) => {
    return {
      ...prev,
      [args?.e?.target?.value]: args?.e?.target?.checked,
    };
  });
}

export { clearDbSubmit, handleChangeCheckbox };
