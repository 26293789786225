import { LogsType } from "../../../components/logs-view/types/logsType";

type dataSetType = {
  errorsLogs: LogsType[];
  loadingErrorsLogs: boolean;
};

const errorsLogs = {
  initialState: { errorsLogs: [] },
  reducers: {
    addAllErrorsLogs(
      dataSet: dataSetType,
      action: { payload: LogsType[] | undefined }
    ) {
      dataSet.errorsLogs = action?.payload ?? [];
    },

    addSingleErrorLog(dataSet: dataSetType, action: { payload: LogsType }) {
      //   dataSet.errorsLogs = [action?.payload, ...dataSet.errorsLogs];
      const newErrorLogs = action?.payload;
      const updatedErrorLogs = [
        newErrorLogs,
        ...dataSet.errorsLogs.slice(0, 999),
      ];
      dataSet.errorsLogs = updatedErrorLogs;
    },

    setErrorsLoading(dataSet: dataSetType, action: any) {
      dataSet.loadingErrorsLogs = true;
    },

    unsetErrorsLoading(dataSet: dataSetType, action: any) {
      dataSet.loadingErrorsLogs = false;
    },

    reset(dataSet: dataSetType, action: any) {
      dataSet.errorsLogs = [];
    },
  },
};

export default errorsLogs;

export const ERRORS_LOGS_ADD_ALL_LOGS = "errorsLogs/addAllErrorsLogs";

export const ERRORS_LOGS_ADD_SINGLE_LOG = "errorsLogs/addSingleErrorLog";

export const ERRORS_LOGS_SET_LOADING = "errorsLogs/setErrorsLoading";

export const ERRORS_LOGS_UNSET_LOADING = "errorsLogs/unsetErrorsLoading";

export const ERRORS_LOGS_RESET_LOGS = "errorsLogs/reset";
