import React from "react";
import "./index.scss";

type LogoutModalPropsType = {
  onClose: Function;
  onValidateLogout: Function;
};

function LogoutModal({ onClose, onValidateLogout }: LogoutModalPropsType) {
  return (
    <div
      className="modal-logout"
      onClick={() => {
        onClose();
      }}
    >
      <div
        className="modal-logout-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <p className="modal-logout-title">
            Are you sure you want to logout ?
          </p>

          <div className="validation-content">
            <button
              className="no-validation-buttons"
              onClick={() => {
                onClose();
              }}
            >
              NO
            </button>
            <button
              className="yes-validation-buttons"
              onClick={() => {
                onValidateLogout();
              }}
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
