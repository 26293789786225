import searchInLogs from "../../../helpers/searchInLogs";

import {
  handleChangeSearchUIinputFieldArgsTypes,
  searchFromUIArgsTypes,
} from "./searchUiTypes";

function searchFromUI(args: searchFromUIArgsTypes) {
  const searchedString = args?.e?.target?.[0]?.value || "";
  args?.setSearchedString(searchedString);
  if (searchedString?.length === 0) {
    args?.setIsSearchedFromUI(false);
    return;
  }
  args?.setIsSearchedFromUI(true);
  args?.setSerchedData(
    searchInLogs(
      searchedString,
      args?.isFiltredFromDB ? args?.filtredDataFromDB : args?.allDebugLogs
    )
  );
}

function handleChangeSearchUIinputField(
  args: handleChangeSearchUIinputFieldArgsTypes
) {
  args?.e?.target?.value?.toString()?.length === 0 &&
    args?.setIsSearchedFromUI(false);
}

export { searchFromUI, handleChangeSearchUIinputField };
