import axios from "axios";
import { toast } from "react-toastify";

type DataFromAxiosType = {
  data: {
    message: string;
  };
};

async function clearDb(body: {
  debug: boolean;
  errors: boolean;
}): Promise<DataFromAxiosType | void> {
  try {
    const { data }: DataFromAxiosType = await axios.post(
      `${
        process.env?.REACT_APP_SERVER_URL || "http://localhost:5000"
      }/logs/clearDb`,
      body
    );
    toast("DB cleared successfully ");
    return {
      data,
    };
  } catch (error: any) {
    toast.error("Clear DB error: " + error?.message);
    console.error("Error in clearDb ", error);
  }
}

export default clearDb;
