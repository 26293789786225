import moment from "moment";

function searchInLogs(
  searchTerm: string,
  ordersData: Record<string, any>[]
): Record<string, any>[] {
  const lowerSearchTerm = searchTerm.toLowerCase();
  const results: Record<string, any>[] = [];

  function isObjectMatch(
    obj: Record<string, any>,
    searchTerm: string
  ): boolean {
    const regex = new RegExp(searchTerm, "i");
    for (const key in obj) {
      const value = obj[key];
      const momentValue = moment(value);
      if (typeof value === "object") {
        if (isObjectMatch(value, searchTerm)) {
          return true;
        }
      } else if (
        !isNaN(momentValue.toDate().getTime()) &&
        momentValue.format("DD/MM/YYYY HH:mm:ss:SSS").match(regex)
      ) {
        return true;
      } else if (value.toString().match(regex)) {
        return true;
      }
    }
    return false;
  }

  for (const order of ordersData) {
    if (isObjectMatch(order, lowerSearchTerm)) {
      results.push(order);
    }
  }

  return results;
}

export default searchInLogs;
