import "./App.scss";

import React from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useSocketConfig } from "./socket/SocketConnection";

import LoggerView from "./components/logs-view";
import Login from "./components/login";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

const defaultHostUrl =
  process.env?.REACT_APP_SERVER_URL || "http://localhost:5000";

const App = (props: any) => {
  const { "host-url": hostUrl = defaultHostUrl } = props || {};
  let user = false;

  try {
    const token = localStorage.getItem("token");
    jwt_decode(token || " ");
    user = true;
  } catch (error) {
    localStorage.removeItem("token");
    user = false;
  }

  useSocketConfig({
    ...(hostUrl ? { host: hostUrl } : { host: defaultHostUrl }),
    init: true,
  });

  React.useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token || " ") as any;
      let currentDate = new Date();
      if (decoded?.exp * 1000 < currentDate.getTime()) {
        toast.error("Token expired");
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    } catch (error) {}
  }, []);

  React.useEffect(() => {
    window.addEventListener("storage", (e: StorageEvent) => {
      if (e?.key === "token" && e?.newValue !== e?.oldValue) {
        try {
          jwt_decode(e?.newValue || " ");
          window.location.href = "/";
        } catch (error) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      }
    });
    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {user && <Route path="/" element={<LoggerView />} />}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
